import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions, Pressable, Image } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../../constants';
import { Ionicons } from '@expo/vector-icons';
import { DashboardLayout, Input } from '@whistlepitch/wp-components';
import { CREATE_CAMPAIGN } from '../../graphql/campaign';
import { useNavigate } from './../../react-router';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import NewLeadModal from '../Leads/NewLeadModal';
import NewUserModal from '../User/NewUserModal';
import UserDetailsModal from '../User/UserDetailsModal';
import Toast from 'react-native-toast-message';
import { Access, UserContext, WpModelSummary, WpUserAction } from '../../contexts/user';
import { INVITE_USERS, LOGOUT_USER, UPDATE_USER } from '../../graphql/user';
import WpModal from '../WpModal';
import ModalBody from '../ModalBody';
import { Label } from '../Input';
import { Switch } from 'react-native-switch';
import StripeCardElement from './StripeCardElement';
import SuperAdminModal from '../User/SuperAdminModal';
import { MaterialIcons } from '@expo/vector-icons';
import * as tools from './../../tools/tools';
import { GET_LANDING_PAGE, GET_USER, SEND_STATS } from '../../graphql/queries';
import EngagementMetricsModal from '../User/EngagementMetricsModal';
import BillingModal from '../User/BillingModal';
import { Button, Theme } from '../Button';
import { CREATE_GROUP } from '../../graphql/lead';
import { WpLandingPage } from '../../contexts/campaign';
import LandingPageModal from '../Campaign/LandingPageModal';
import HowToModal from './HowToModal';


interface SendStats {
  total: number,
  pending: number,
  sent: number,
  paused: number,
  finishes?: string,
  campaigns: WpModelSummary[]
}

interface DropdownOption {
  id: string,
  label: string,
  value: string
}

interface SidebarStructure {
  id: string,
  label: string,
  icon: string,
  url: string,
}

export default function BasicLayout({ children = null, active = '/dashboard', campaignId, color = Constants.colors.darkBlue, small, hoverable, style = {}, onSearch }: { children?: null | JSX.Element | JSX.Element[], active?: string, campaignId?: string, color?: string, small?: boolean, hoverable?: boolean, style?: object, onSearch?: (val?: string) => void }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);

  const [hovering, setHovering] = useState<boolean>();
  const [showNewLead, setShowNewLead] = useState<boolean>(false);
  const [invitingUser, setInvitingUser] = useState<boolean>(false);
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [icon, setIcon] = useState<"checkmark-circle" | "radio-button-off" | "radio-button-on">();
  const [showInviteUser, setShowInviteUser] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [stripeError, setStripeError] = useState<string>();
  const [stripeLoading, setStripeLoading] = useState<boolean>();
  const [settingUpBilling, setSettingUpBilling] = useState<boolean>(false);
  const [showSuperAdmin, setShowSuperAdmin] = useState<boolean>(false);
  const [showEngagmentMetrics, setShowEngagmentMetrics] = useState<boolean>(false);
  const [showCalendly, setShowCalendly] = useState<boolean>(false);
  const [limitedAccess, setLimitedAccess] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [stats, setStats] = useState<SendStats>();
  const [statCampaigns, setStatCampaigns] = useState<String>();
  const [sidebarStructure, setSidebarStructure] = useState<SidebarStructure[]>();
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);
  const [editingLandingPage, setEditingLandingPage] = useState<boolean>(false);
  const [landingPage, setLandingPage] = useState<WpLandingPage | undefined>();
  const [showHowTo, setShowHowTo] = useState<boolean>(false);
  const [allowSelfOnboarding, setAllowSelfOnboarding] = useState<boolean>(false)

  const [updateUser, updateUserData] = useMutation(UPDATE_USER, {
    fetchPolicy: "no-cache",
  });

  const { data, loading, error } = useQuery(GET_USER, {
    fetchPolicy: "network-only",
    variables: {
      includeBilling: true
    }
  });
  const [newCampaign, newCampaignData] = useMutation(CREATE_CAMPAIGN, {
    fetchPolicy: "no-cache"
  });
  const [logoutUser, logoutUserData] = useLazyQuery(LOGOUT_USER, {
    fetchPolicy: "no-cache"
  });
  // const [sendStats, sendStatsData] = useLazyQuery(SEND_STATS, {
  //   fetchPolicy: "no-cache"
  // });
  const [inviteUser, inviteUserData] = useMutation(INVITE_USERS, {
    fetchPolicy: "no-cache"
  })
  const [createGroup, createGroupData] = useMutation(CREATE_GROUP, {
    fetchPolicy: "no-cache"
  });
  const [getLandingPage, getLandingPageData] = useLazyQuery(GET_LANDING_PAGE, {
    fetchPolicy: "no-cache",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      if (data.user.success) {
        dispatch({ type: WpUserAction.REFRESH_USER, user: data?.user?.user })
      } else {
        localStorage.removeItem('token');
        dispatch({ type: WpUserAction.LOGOUT_USER, user: data?.user?.user })
        navigate('/login')
      }
    }
  }, [data])

  useEffect(() => {
    if (updateUserData.data) {
      window.location.reload()
    }
  }, [updateUserData])

  useEffect(() => {
    if (state.user?.organization.access) {
      setLimitedAccess(state.user?.organization.access === Access.LIMITED)
    }

    if (state.user?.organization) {
      setAllowSelfOnboarding(state.user.organization.allowSelfOnboarding)
    }
  }, [state.user?.organization])

  useEffect(() => {
    if (getLandingPageData?.data?.landingPage?.landingPage) {
      setLandingPage(getLandingPageData?.data?.landingPage?.landingPage)
      let lindex = dropdownOptions.findIndex((o) => o.id === 'landing-page')
      if (lindex === -1 && Constants.oppText && !state.user?.organization.v2) {
        setDropdownOptions([
          ...dropdownOptions,
          { id: 'landing-page', label: 'Landing Page', value: 'landing-page' }
        ])
      }
    }
  }, [getLandingPageData])

  useEffect(() => {
    if (state.user) {
      if (state.user?.new && Constants.blastView) {
        onSetupBilling();
      }

      let dOptions = Constants.dropdownOptions;
      if (Constants.oppText) {
        if (landingPage) {
          dOptions = [
            ...dOptions,
            { id: 'landing-page', label: 'Landing Page', value: 'landing-page' }
          ]
        }
        if (!state.user?.organization?.v2) {
          dOptions = [
            ...dOptions,
            { id: 'campaign-library', label: 'Campaign Library', value: 'campaign-library' }
          ]
        }
      }
      if (Constants.tattooCare) {
        dOptions = [
          ...dOptions,
          { id: 'body-parts', label: 'Body Parts', value: 'body-parts' }
        ]
      }
      if (Constants.blastView) {
        dOptions = [
          ...dOptions,
          { id: 'how-to', label: 'How To Videos', value: 'how-to' }
        ]
      }

      if (state.user?.organization.access === Access.LIMITED) {
        setSidebarStructure(Constants.sidebarStructureLimited)
        setDropdownOptions(Constants.dropdownOptionsLimited)
      } else if (state.user.wpAdmin) {
        if (state.user.organization?.showEventsPage) {
          setSidebarStructure([...Constants.sidebarStructureAdmin, Constants.sidebarEventsPage])
        } else {
          setSidebarStructure(Constants.sidebarStructureAdmin)
        }
        if (Constants.oppText) {
          if (landingPage && !state.user?.organization.v2) {
            setDropdownOptions([
              ...Constants.dropdownOptionsWpAdmin,
              { id: 'landing-page', label: 'Landing Page', value: 'landing-page' },
              { id: 'campaign-library', label: 'Campaign Library', value: 'campaign-library' }
            ])
          } else {
            setDropdownOptions(state.user?.organization.v2 ?
              Constants.dropdownOptionsWpAdmin : [
                ...Constants.dropdownOptionsWpAdmin,
                { id: 'campaign-library', label: 'Campaign Library', value: 'campaign-library' }
              ])
          }
        } else if (Constants.tattooCare) {
          setDropdownOptions([
            ...Constants.dropdownOptionsWpAdmin,
            { id: 'body-parts', label: 'Body Parts', value: 'body-parts' }
          ])
        } else if (Constants.blastView) {
          setDropdownOptions([
            ...Constants.dropdownOptionsWpAdmin,
            { id: 'how-to', label: 'How To Videos', value: 'how-to' }
          ])
        } else {
          setDropdownOptions(Constants.dropdownOptionsWpAdmin)
        }
      } else if (state.user.organization?.v2) {
        setSidebarStructure(Constants.sidebarStructureV2)
        setDropdownOptions(Constants.dropdownOptions)
      } else if (["25", "34", "35", "43", "47", "50"].indexOf(state.user.organization.id) > -1 || state.user.organization.showMessages) {
        if (state.user.organization?.showEventsPage) {
          setSidebarStructure([...Constants.sidebarStructureAdmin, Constants.sidebarEventsPage])
        } else {
          setSidebarStructure(Constants.sidebarStructureAdmin)
        }
        setDropdownOptions(dOptions)
      } else if (Constants.blastView || Constants.oppText) {
        if (state.user.organization?.showEventsPage) {
          setSidebarStructure([...Constants.sidebarStructureAdmin, Constants.sidebarEventsPage])
        } else {
          setSidebarStructure(Constants.sidebarStructureAdmin)
        }
        setDropdownOptions(dOptions)
      } else {
        if (state.user.organization?.showEventsPage) {
          setSidebarStructure([...Constants.sidebarStructureAdmin, Constants.sidebarEventsPage])
        } else {
          setSidebarStructure(Constants.sidebarStructureAdmin)
        }
        setDropdownOptions(dOptions)
      }

      if (Constants.oppText) {
        if (getLandingPageData) {
          getLandingPageData.refetch();
        } else {
          getLandingPage();
        }
      }
    } else {
      setSidebarStructure(Constants.sidebarStructureLimited)
      setDropdownOptions(Constants.dropdownOptionsLimited)
    }
  }, [state.user])

  // useEffect(() => {
  //   if (sendStatsData.data && sendStatsData.data.sendStats.success) {
  //     setStats(sendStatsData.data.sendStats.stats)
  //     let names = [];
  //     for (let i = 0; i < sendStatsData.data.sendStats.stats.campaigns.length; i++) {
  //       names.push(sendStatsData.data.sendStats.stats.campaigns[i].name)
  //     }
  //     setStatCampaigns(names.join(", "))
  //   }
  // }, [sendStatsData.data]);

  useEffect(() => {
    if (Constants.tattooCare) {
      let els1 = document.querySelector(".WP_Sidebar_Brand")
      let els2 = document.querySelector(".WP_Sidebar_BrandLogo")
      if (els1) {
        els1.style.opacity = "0.0";
      }
      if (els2) {
        els2.style.opacity = "0.0";
      }
    }
  }, [sidebarStructure])

  useEffect(() => {
    if (logoutUserData.data) {
      localStorage.removeItem('token');
      dispatch({ type: WpUserAction.LOGOUT_USER, user: data?.user?.user })
      navigate("/login")
    }
  }, [logoutUserData.data])

  // useEffect(() => {
  //   if (state.user?.organizations) {
  //     let options = [];
  //     for (let i = 0; i < state.user.organizations.length; i++) {
  //       let org = state.user.organizations[i]
  //       options.push({
  //         id: org.id,
  //         label: org.name,
  //         value: org.name
  //       })
  //     }
  //     setOrgOptions(options)
  //   }
  // }, [state.user?.organizations])

  useEffect(() => {
    if (newCampaignData.data?.createCampaign?.campaign) {
      navigate("/campaign/" + newCampaignData.data.createCampaign?.campaign.id + '?' + Math.floor(1000 + Math.random() * 9000))
    }
  }, [newCampaignData.data])

  useEffect(() => {
    if (inviteUserData.data?.inviteUsers?.users && inviteUserData.data?.inviteUsers?.users.length) {
      onCreatedNewUser([{ email: inviteUserData.data?.inviteUsers?.users[0].email }])
    }
  }, [inviteUserData])

  useEffect(() => {
    if (createGroupData?.data?.createGroup) {
      if (createGroupData.data.createGroup.success) {
        navigate('/group/' + createGroupData.data.createGroup.group.id)
      } else {
        Toast.show({
          type: 'error',
          text1: 'You\'ve reached your limit',
          text2: 'Click here to upgrade for more groups',
          onPress: onSetupBilling
        });
      }
    }
  }, [createGroupData?.data])

  const onCreateGroup = () => {
    createGroup()
  }

  const doneEditLandingPage = () => {
    setEditingLandingPage(false);
    getLandingPageData.refetch();
  }

  const doneHowTo = () => {
    setShowHowTo(false);
  }

  const onHover = () => {
    if (hoverable) {
      setHovering(true);
    }
  }

  // const loadSendStats = () => {
  //   if (state.user) {
  //     if (sendStatsData.data) {
  //       sendStatsData.refetch();
  //     } else {
  //       sendStats()
  //     }
  //   }
  // }

  // const onDashboardChange = (url: string) => {
  //   return url;
  // }

  const onDashboardChange = (e: any) => {
    if (e === 'add') {
      setShowInviteUser(true);
    } else if (e === 'briefcase') {
      setShowSuperAdmin(true);
    } else if (e === 'users') {
      navigate('/users');
    } else if (e === 'account') {
      navigate('/account');
      // setShowUserDetails(true);
    } else if (e === 'metrics') {
      setShowEngagmentMetrics(true);
    } else if (e === 'landing-page') {
      setEditingLandingPage(true);
    } else if (e === 'campaign-library') {
      navigate('/campaign-library');
    } else if (e === 'body-parts') {
      navigate('/body-parts');
    } else if (e === 'how-to') {
      setShowHowTo(true);
    }
  }

  const onDashboardAction = (e: any) => {
    if (e === 'add') {
      setShowInviteUser(true);
    } else if (e === 'briefcase') {
      setShowSuperAdmin(true);
    }
  }

  const onCreateCampaign = () => {
    newCampaign()
  }

  const onCreateLead = () => {
    setShowNewLead(true);
  }

  const onCloseNewLead = () => {
    setRefresh(refresh + 1)
    setShowNewLead(false);
  }

  const openUserDetails = () => {
    setShowUserDetails(true);
  }

  const closeUserDetails = () => {
    setShowUserDetails(false);
  }

  const onAddNewLead = () => {
    Toast.show({
      type: 'success',
      text1: 'Leads Added',
      text2: `Added to your ${campaignId ? 'campaign' : 'group'}`
    });
    setShowNewLead(false);
  }

  const onCompleteCard = (error, paymentMethod) => {
    if (error) {
      setStripeError(error)
    } else {
      inviteUser({
        variables: {
          users: [{
            email: email
          }],
          token: paymentMethod ? paymentMethod.id : undefined,
          showCalendly: showCalendly,
          brand: Constants.brand
        }
      })
      setShowInviteUser(false);
    }
  }

  const onCloseNewUser = () => {
    setInvitingUser(false);
  }

  const onSidebarAction = (e: any) => {
    if (e === 'add-leads') {
      if (Constants.blastView) {
        onCreateGroup();
      } else {
        onCreateLead();
      }
    } else if (e === 'new-campaign') {
      onCreateCampaign()
    } else if (e === 'USER') {
      setInvitingUser(true)
    }
  }

  const onSidebarNavigate = (url: string) => {
    if (url === '/library') {
      navigate('/library');
    } else if (url === '/dashboard') {
      navigate("/dashboard")
    } else if (url === '/groups') {
      navigate("/groups")
    } else if (url === '/landing-pages') {
      navigate("/landing-pages")
    } else if (url === '/campaigns') {
      navigate("/campaigns")
    } else if (url === '/users') {
      navigate("/users")
    } else if (url === '/messages') {
      navigate("/messages")
    } else if (url === '/events') {
      navigate("/events")
    } else if (url === '/account') {
      openUserDetails()
    }
    return url
  }

  const onCreatedNewUser = (emails: { email: string }[]) => {
    let email_text = ''
    if (emails.length === 1) {
      email_text = emails[0].email;
    } else if (emails.length === 2) {
      email_text = `${emails[0].email} and 1 other`
    } else if (emails.length > 2) {
      email_text = `${emails[0].email} and ${emails.length - 1} others`
    }

    Toast.show({
      type: 'success',
      text1: 'User Invitations',
      text2: `An invite has been sent to ${email_text}`
    });
    setInvitingUser(false);
  }

  const onLogout = () => {
    logoutUser();
  }

  const changeEmail = (e: any) => {
    setEmail(e.target.value)
  }

  const onSetupBilling = () => {
    setSettingUpBilling(true)
  }

  const doneSetupBilling = () => {
    setSettingUpBilling(false)
  }

  // const onCompleteBilling = () => {
  //   window.location.reload();
  // }

  const onCompleteBilling = (token?: string, plan?: string) => {
    if (state.user) {
      updateUser({
        variables: {
          userId: state.user.id,
          user: {
            token: token,
            plan: plan
          }
        }
      })
    }
  }

  const closeSuperAdmin = () => {
    setRefresh(refresh + 1);
    setShowSuperAdmin(false);
  }

  const closeEngagementMetrics = () => {
    setRefresh(refresh + 1);
    setShowEngagmentMetrics(false);
  }

  if (!sidebarStructure) {
    return <View></View>;
  }

  return (
    <View style={{ flex: 1, position: 'relative', alignContent: 'stretch' }}>
      {stats && stats.total ?
        <View style={{ height: 30, backgroundColor: Constants.colors.darkBlue, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', padding: 12 }}>
            <Text style={{ fontFamily: 'GothamBold', color: 'white', fontSize: 14 }}>Sent {stats.sent} out of {stats.total}</Text>
            {stats.paused ?
              <View style={{ height: 30, backgroundColor: Constants.colors.darkBlue, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Text style={{ fontFamily: 'GothamBold', color: 'white', fontSize: 12 }}>({stats.paused})</Text>
              </View> : null}
          </View>
          <View style={{ alignItems: 'flex-end', padding: 12 }}>
            <Text style={{ fontFamily: 'GothamBold', color: 'white', fontSize: 14 }}>For {statCampaigns}</Text>
            {stats.finishes ? <Text style={{ fontFamily: 'GothamBold', color: 'white', fontSize: 12 }}>Finish {tools.formatDateString(stats.finishes)}</Text> : null}
          </View>
        </View> : null}
      {Constants.blastView && state.user?.organization.billing && !state.user?.organization.billing.active ?
        <View style={{ height: 40, backgroundColor: Constants.colors.darkBlue, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', padding: 12 }}>
            <MaterialIcons style={{ marginRight: 15 }} name="error-outline" size={22} color="white" />
            <Text style={{ fontFamily: 'GothamBold', color: 'white', fontSize: 14 }}>You'll need to setup billing before you can send out any messages</Text>
          </View>
          <View style={{ alignItems: 'flex-end', padding: 12 }}>
            <Button text="Setup Billing" shadow={false} width={150} theme={Theme.WHITE} small={true} onPress={onSetupBilling} />
          </View>
        </View> : null}
      <DashboardLayout
        version={Constants.version}
        searchBar={onSearch ? true : false}
        onDashboardSearch={onSearch}
        onDashboardSearchClean={onSearch}
        brand={Constants.brand}
        name={state.user?.name ?? ''}
        imgUrl={state.user?.organization.image}
        dropOption={dropdownOptions}
        setRoute={active}
        sidebarStructure={sidebarStructure}
        actionOne={!Constants.blastView && state.user?.wpAdmin}
        actionTwo={state.user?.wpAdmin}
        onDashboardAction={onDashboardAction}
        onDropdownSelect={onDashboardChange}
        onChange={onSidebarNavigate}
        onAction={onSidebarAction}
        onLogout={onLogout}
        // actionTwoSideBar={!limitedAccess}
        actionTwoSideBar={!limitedAccess}
        actionOneSideBar={!limitedAccess && !Constants.oppText}
        actionOneText={Constants.localizations.actionOneText}
        actionTwoText={Constants.localizations.actionTwoText}
      >
        <NewLeadModal isVisible={showNewLead} onClose={onCloseNewLead} onCreated={onCloseNewLead} onAdded={onAddNewLead} />
        <NewUserModal isVisible={invitingUser} onClose={onCloseNewUser} onCreated={onCreatedNewUser} />
        <UserDetailsModal isVisible={showUserDetails} user={state.user ?? undefined} onClose={closeUserDetails} />
        <SuperAdminModal isVisible={showSuperAdmin} onClose={closeSuperAdmin} />
        <EngagementMetricsModal isVisible={showEngagmentMetrics} onClose={closeEngagementMetrics} />
        <BillingModal isVisible={settingUpBilling} loading={updateUserData.loading} onClose={doneSetupBilling} onComplete={onCompleteBilling} newUser={true} />
        <LandingPageModal landingPage={landingPage} isVisible={editingLandingPage} closeModal={doneEditLandingPage} />
        <HowToModal isVisible={showHowTo} onClose={doneHowTo} />

        <WpModal isVisible={showInviteUser}>
          <ModalBody closeModal={setShowInviteUser}>
            <Input style={{ marginBottom: 20 }} label="Email" placeholder="Enter email..." onChange={changeEmail} onInput={changeEmail} />
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 15, position: 'relative' }}>
              <Label name="Calendly Feature" />
              <Switch
                value={showCalendly}
                onValueChange={setShowCalendly}
                backgroundActive={Constants.colors.primary}
                backgroundInactive={Constants.colors.infoLight}
                circleActiveColor={'#FFF'}
                circleInActiveColor={'#FFF'}
                renderActiveText={false}
                renderInActiveText={false}
                circleBorderWidth={0}
                circleSize={22}
                switchLeftPx={3}
                switchRightPx={3}
                barHeight={26}
              />
            </View>
            <View style={{ alignItems: 'flex-end', marginBottom: 15 }}>
              <Text style={{ cursor: 'pointer', flex: 1, color: Constants.colors.blue, fontFamily: 'Gotham' }} onPress={() => onCompleteCard(undefined, undefined)}>Re-send Invite</Text>
            </View>
            <StripeCardElement onComplete={onCompleteCard} stripeLoading={stripeLoading || loading} setStripeLoading={setStripeLoading} />
            {stripeError ? <Text style={{ color: Constants.colors.red }}>{stripeError}</Text> : null}
          </ModalBody>
        </WpModal>
        <View key={`view-${refresh}`} style={{ flex: 1, marginTop: 25, paddingBottom: 40 }}>
          {children}
        </View>
      </DashboardLayout>

      <div className="wp-alternative-icon" style={{ position: 'absolute', top: 30, left: 30, padding: 0, zIndex: 100, backgroundColor: 'white' }}>
        {Constants.tattooCare ? <Image style={{ width: 40, height: 40 }} source={require('./../../assets/tattoocare_logo.png')} /> : null}
      </div>
    </View>
  );
}

const styles = StyleSheet.create({
  bubble: {
    fontFamily: 'GothamMedium',
    paddingVertical: 13,
    paddingHorizontal: 20,
    borderRadius: 20,
    height: 40,
    fontSize: 12,
    backgroundColor: '#F5F7FB',
    marginBottom: 15
  },
  bubbleSmall: {
    fontFamily: 'GothamMedium',
    paddingVertical: 6,
    paddingHorizontal: 15,
    borderRadius: 12,
    height: 24,
    fontSize: 10,
    backgroundColor: '#F5F7FB',
  }
});

const cssStyles = {
}
